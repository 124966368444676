.cards {
	display: block;
	max-width: 90%;
	margin: 0 auto;
}

.padd {
	padding: 20px;
}

h2, h3 {
	font-family: "blogger";
	font-size: 18pt;
	font-weight: 300;
}

h1 {
	font-family: "blogger";
	font-size: 35pt;
	line-height: 40pt;
}

p {
	font-size: 13pt;
	margin-top: 20pt;
	line-height: 25pt;
	text-align: justify;
}

.join-small {
	background-image: url(/img/section_green_bg.png);
	text-align: center;
	color: white;

	p.soical-icons {
		text-align: center;
		padding-bottom: 20px;
	}

	input {
		width: 100%;
		max-width: 330px;
		@include border-radius(5px);
		padding: 10px 30px;
		font-size: 20pt;
		border: 2px solid #26b671;
		color: black;
		@media(max-width: 480px){
			width: 90%;
		}
	}

	button {
		width: 100%;
		width: auto;
		margin-top: 10pt;
		font-size: 20pt;
		@include border-radius(5px);
		background: #3c3c3b;
		border: 2px solid #242424;
		color: #fff;
		padding: 10px 20px;
		text-transform: uppercase;
		font-family: blogger;
		font-weight: bold;
		@media(max-width: 480px){
			width: 90%;
		}
	}

	p { font-size: 24px; }
}
