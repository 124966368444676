
@media(min-width: 1280px) {
	#fullpage {
		.section {
			min-height: 100vh;
		}
	}
}


#map {
	width: 1400px;
	max-width: 100%;
	height: 900px;
	margin: 0 auto;
	position: relative;

	canvas {
		background-color: #fff;
	}
}
#map-slogans {
	position: absolute; bottom: 5em; left: 10%; z-index: 9; pointer-events: none; width: 80%;
	h1 { font-size: 80px; margin: 40px 0 100px 0; line-height: auto;}
	h2 { font-weight: 300; font-size: 30px; line-height: auto; }
	p { font-size: 18px; line-height: 1.778; }

	@media (max-width: 1500px) {
		h1 { font-size: 80px * 0.8; }
		h2 { font-size: 30px * 0.9; }
	}

	@media (max-width: 1200px) {
		h1 { font-size: 80px * 0.6; margin-bottom: 50px; }
		h2 { font-size: 30px * 0.7; }
		p { font-size: 18px * 0.8; }
	}
}
#map-container {
	height: 100%;
	position: relative;
	cursor: url(/img/cursor-minus.png) 10 10, auto;
}

#map-container.plus {
	cursor: url(/img/cursor-plus.png) 10 10, auto;
}

#map-toggle { position: absolute; bottom: 50px; right: 50px; }
.fp-tableCell { position: absolute !important; }
.switch {
	position: absolute;
	bottom: 0; right: 0;
	bottom: 20px;
	right: 60px;
	width: auto;
	p {
		display: inline-block;
		text-transform: uppercase;
		color: #000;
		float: right;
		font-family: 'blogger';
		font-size: 14px;
	}
	#map-switch {
		float: right;
		position: relative;
		width: 80px;
		height: 34px;
		margin: 0 20px;
	}
}
#map-switch {
	background: black;
	display: block;
	// position: absolute;
	// bottom: 20px;
	// right: 60px;
	// width: 80px;
	// height: 34px;
	background: #de4f4f;
	border: 2px solid black;
	@include border-radius(17px);
	.circle {
		position: absolute;
		background: black;
		height: 30px;
		width: 30px;
		top: 0; left: 0;
		@include border-radius(15px);
		img { height: 20px; width: 20px; position: absolute; top: 5px; left: 4px; }
	}
	@include transition(0.5s);
}

#map-switch.plus {
	background: #26b671;

}
h1,h2,h3,h4,h5,h6 { font-family: blogger; font-weight: bold; }

a { text-decoration: none !important; }


.cards {
	display: block;
	position: absolute;
	top: 300px;
	left: 10%;
	height: 370px;

	.card-container {
		$border-color: #d3d3d3;

		display: block;
		position: absolute;
		top: 0;	left: 0;
		width: 261px; height: 362px;

		@for $i from 1 through 4 {
			&.card-#{$i} { top: 0; left: 200px; z-index: 100 - $i;}
		}

		.card {

			display: block;
			position: absolute;
			top: 0;	left: 0;
			width: 100%; height: 100%;
			background: transparent;
			.front, .back {
				position: absolute;
				top: 0; left: 0;
				width: 100%; height: 100%;
				@include backface-visibility(hidden);
			}

			.front {
			}

			.back {
				.description {
					position: absolute;
				  	top: 70px; left: 10%;
				  	width: 80%;
				  	font-family: blogger;
				  	font-size: 13px;

				  	table {
				  		padding: 0;
				  		margin: 0;
				  		width: 100%;
				  		td {
				    		line-height: 32px;
				    		color: white;
				    		padding: 0;
				    		margin: 0;
				    	}
				    	td:first-child {
				    		text-transform: uppercase;
				    	}
				    	td:last-child {
				    		font-weight: bold;
				    		text-align: right;
				    	}
				  	}
				}

			}

			.button-plus, .button-minus {
				position: absolute;
				@include transition(stroke 1s);
			}

			$button-distance: 11px;
			.button-plus {
				top: $button-distance; right: $button-distance;
			}

			.button-minus {
				bottom: $button-distance; left: $button-distance;
			}

			.counter {
				position: absolute;
				border: 2px solid $border-color;
				padding: 0 30px 0 30px;
				color: #3c3c3b;
				height: 38px;
				line-height: 36px;
				background: white;
				font-family: blogger;
				font-size: 18px;
				font-weight: bold;
				@include transition(border-color 1s);

				&.counter-negative {
					bottom: 20px; left: 60px;
					@include border-radius-right(19px);
					border-left: none;
				}

				&.counter-positive {
					top: 20px; right: 57px;
					@include border-radius-left(19px);
					border-right: none;
				}
			}

			.arrow {
				float: right;
				margin-top: 8px;
				margin-left: 8px;

			}

			img.poster {
				position: absolute;
				left: 27px; top: 76px;
				z-index: 9999;
			}


		}
	}
}

.card-top, .card-bottom { @include transition(fill 1s); fill: white; }

.positive .card-top { fill: #35c982;}
.negative .card-bottom { fill: #ff6363;}

.cards .card-container.positive .button-plus .outline { stroke: #26b671; }
.cards .card-container.positive .card .counter.counter-positive { border-color: #26b671; }
.cards .card-container.positive .card .card-top { stroke: #26b671; }
.cards .card-container.positive .card .middle-border { fill: #26b671; }
.cards .card-container.positive .card circle { stroke: #26b671; }

.cards .card-container.negative .button-minus .outline { stroke: #de4f4f; }
.cards .card-container.negative .card .counter.counter-negative { border-color: #de4f4f; }
.cards .card-container.negative .card .card-bottom { stroke: #de4f4f; }
.cards .card-container.negative .card .middle-border { fill: #de4f4f; }
.cards .card-container.negative .card circle { stroke: #de4f4f; }

.text {
	width: 40%;
	position: absolute;
	top: 300px;
	right: 10%;
	text-align: justify;

	h1 {
		margin-bottom: 50px;
		font-size: 72px;
		line-height: 72px;
		text-align: left;
	}

	p {
		font-size: 24px;
		line-height: 2;
	}

	@media (max-width: 1500px) {
		h1 { font-size: 72px * 0.8; }
		p { font-size: 24px * 0.9; }
		& { right: 5%; width: 40%; }
	}

	@media (max-width: 1200px) {
		h1 { font-size: 72px * 0.6; }
		p { font-size: 24px * 0.8; }
		& { right: 5%; }
	}
}

.cards-section { background-image: url(/img/section_bg.png); }
.join-section {
	background-image: url(/img/section_green_bg.png);
	text-align: center;
	h1 {
		font-size: 50pt;
		margin-bottom: 50px;
		color: #fff;
	}

	.subheading { color: white; font-size: 24px; margin-bottom: 50px;}

	input {
		@include border-radius(5px);
		padding: 10px 30px;
		font-size: 20pt;
		border: 2px solid #26b671;
	}

	button {
		font-size: 20pt;
		margin-left: -10px;
		@include border-radius(5px);
		background: #3c3c3b;
		border: 2px solid #242424;
		color: #fff;
		padding: 10px 20px;
		text-transform: uppercase;
		font-family: blogger;
		font-weight: bold;
	}

	// .quote { color: white; font-style: italic; font-size: 24px; margin-top: 8em; margin-bottom: 0.5em;}
	.quote { color: white; font-style: italic; font-size: 24px; margin-top: 1em; margin-bottom: 0.5em;}
	.soical-icons {
		margin-top: 4em; margin-bottom: 4em;
		a {
			display: inline-block;
			margin: 0 7px;
		}
	}
	.quote:before { content: '\201C'; }
	.quote:after { content: '\201D'; }
	.author { color: white; font-size: 24px; font-weight: bold; margin: 0; }
}



#plus-minus {
	position: absolute;
	top: 50%; left: -51px;
	width: 133px*2; height: 56px;
	z-index: 99999;
	#pm-background {
		position: absolute;
		height: 100%;
		width: 100%	;
		top: 0; left: 0;
		img {
			float: left;
			margin: 0;
			padding: 0;
		}
	}

	#plus {
		position: absolute;
		top: 0; right: 0;
		z-index: 99999;
		circle { stroke: #35c982; }
	}

	#minus {
		position: absolute;
		top: 0; left: 0;
		z-index: 99999;
		circle { stroke: #ff6363; }
	}

	#check {
		position: absolute;
		top: 0px; left: 105px;
		z-index: 99999;
	}

	.counter {
		position: absolute;
		top: 10px;
		color: white;
		font-size: 28pt;
		//font-weight: bold;
	}

	.counter.minus {
		left: 70px;
	}

	.counter.plus {
		right: 70px;
	}
}

#pill {
	position: absolute;
	top: 50%;
	right: 50px;
	width: 18px;
	z-index: 9999999;
	svg {
		float: left;
	}
	svg .fillable {
		@include transition(0.6s);
	}
	svg.green .fillable {
		fill: #35C982;
	}
	display: none;
}

@media (max-width: 1600px) {
	.cards { left: 5%; }

	#pill { right: 20px; }
}

@media (max-width: 1200px) {
	.cards{ left: -50px; }
}

@media (max-width: 1000px) {
	.cards { left: 0%; }
}



.thank-you {
	color: white;
	h1 {
		font-size: 50pt;
	}
	h2 {
		font-size: 18pt;
	}
	img { margin-top: 40px; margin-bottom: 200px;}
}

.join, .thank-you {
	position: absolute;
	width: 100%;
}
