@mixin border-radius($radius) {
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin border-radius-left($radius) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin border-radius-right($radius) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

// generic transform
@mixin transform($transforms) {
          transform: $transforms;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
	 @include transform(scale($scale));
} 
// translate
@mixin translate($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin($origin) {
  transform-origin: $origin;
}

@mixin transform-style($val) {
  transform-style: $val;
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}
// http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/

@mixin keyframes($animation-name) {
  @keyframes $animation-name {
    @content;
  }
}

@mixin animation($str) {
  animation: #{$str};      
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin transition($args...) {
  transition: $args;
}

@mixin backface-visibility($args) {
  backface-visibility: $args;
}