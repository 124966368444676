// Vendors
@import '../bower_components/bootstrap/scss/bootstrap-reboot';
@import '../bower_components/bootstrap/scss/bootstrap-grid';
// @import '../bower_components/bootstrap/scss/utilities-responsive';
@import '../bower_components/fullpage.js/jquery.fullPage.scss';

@import 'mixins';
@import 'geofont';
@import 'bloggerfont';

body { font-family: "geosquad"; line-height: 2.5em; font-size: 11pt; visibility: hidden; }

.menu {
	position: fixed;
	// left: calc(50px + 30%); 
	right: 110px; 
	top: 30px;
	z-index: 999999;
	max-width: 50%;
	list-style: none;
	li {
		float: left;
		text-transform: uppercase;
		z-index: 999999;
		text-decoration: none;
		a {
			font-family: 'blogger';
			font-weight: bold;
			font-size: 16px;
			line-height: 60px;
			color: #000;
			display: block;
			padding: 0 20px;
			z-index: 999999;
			position: relative;
		}
	}
	&.smaller {
		// top: 30px;
		// right: 110px;
	}
}

.yt_popup {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999999;
	background: #fff;
	.video {
		display: inline-block;
		position: relative;
		width: 80%;
		max-width: 80%;
		height: 80%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	    box-shadow: 0 0px 20px 0px #3c3c3b;
	    border: 2px solid #e4e4e4;
	}
	.close {
		font-family: 'blogger';
		text-transform: uppercase;
		position: absolute;
		top: -15px;
		right: -15px;
		width: 30px;
		height: 30px;
		background: #fff;
		border: 2px solid #e4e4e4;
		border-radius: 5px;
		color: #000;
		display: block;
		font-size: 20px;
		line-height: 30px;
		text-align: center;
	}
	iframe {
		border: 5px solid #fff;
		display: block;
		width: 100%;
		height: 100%;
		margin-top: 20%;
		margin: 0 auto;
	}
}

#logo {
	position: fixed;
	left: 10px; top: 30px;
	z-index: 999999;
	max-width: 30%;
	pointer-events: none;
	// transition: top .5s, left .5s;
	&.smaller {
		// top: 30px;
		// left: 10px;
	}
}

#join-button { 
	position: fixed;
	right: 10px; top: 30px;
	background: #35c982; 
	color: white; 
	text-transform: uppercase; 
	border: 2px #26b671 solid; 
	@include border-radius(4px); 
	padding: 10px 20px; 
	font-weight: bold; 
	font-size: 20pt; 
	text-shadow: 1px 1px 8px rgba(20, 20, 20, 0.4);
	z-index: 999999;
	font-family: blogger;
	&.smaller {
		// top: 30px;
		// right: 10px;
	}
}

.big-devices {
	@import 'big_devices';
}

.small-devices {
	@import 'small_devices';
	background-image: url(/img/section_bg.png);
	width: 100%;
	padding-top: 80px;
}

.big-devices {
	display: block;
}

.small-devices {
	display: none;
}

@media(max-width: 1280px) {
	.big-devices {
		display: none;
	}

	.small-devices {
		display: block;
	}

}
// @media(max-width:48em){
@media(max-width: 1280px){
	#logo {
		left: 20px; top: 20px;
		position: absolute;
	}
}

#email.error {
	border-color: red;
	background-color: #ff6961;
}

.email-info { font-size: 20px; }