/* Generated by Font Squirrel (http://www.fontsquirrel.com) on October 1, 2015 */



@font-face {
    font-family: 'blogger';
    src: url('/font/blogger_sans-webfont.eot');
    src: url('/font/blogger_sans-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/blogger_sans-webfont.woff2') format('woff2'),
         url('/font/blogger_sans-webfont.woff') format('woff'),
         url('/font/blogger_sans-webfont.ttf') format('truetype'),
         url('/font/blogger_sans-webfont.svg#blogger_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'blogger';
    src: url('/font/blogger_sans-bold-webfont.eot');
    src: url('/font/blogger_sans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/blogger_sans-bold-webfont.woff2') format('woff2'),
         url('/font/blogger_sans-bold-webfont.woff') format('woff'),
         url('/font/blogger_sans-bold-webfont.ttf') format('truetype'),
         url('/font/blogger_sans-bold-webfont.svg#blogger_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'blogger';
    src: url('/font/blogger_sans-light-webfont.eot');
    src: url('/font/blogger_sans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/blogger_sans-light-webfont.woff2') format('woff2'),
         url('/font/blogger_sans-light-webfont.woff') format('woff'),
         url('/font/blogger_sans-light-webfont.ttf') format('truetype'),
         url('/font/blogger_sans-light-webfont.svg#blogger_sanslight') format('svg');
    font-weight: 300;
    font-style: normal;

}