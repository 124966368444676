@font-face {
    font-family: 'geosquad';
    src: url('/font/geosquad-bold-webfont.eot');
    src: url('/font/geosquad-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/geosquad-bold-webfont.woff2') format('woff2'),
         url('/font/geosquad-bold-webfont.woff') format('woff'),
         url('/font/geosquad-bold-webfont.ttf') format('truetype'),
         url('/font/geosquad-bold-webfont.svg#geosquadbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'geosquad';
    src: url('/font/geosquad-bold-italic-webfont.eot');
    src: url('/font/geosquad-bold-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/geosquad-bold-italic-webfont.woff2') format('woff2'),
         url('/font/geosquad-bold-italic-webfont.woff') format('woff'),
         url('/font/geosquad-bold-italic-webfont.ttf') format('truetype'),
         url('/font/geosquad-bold-italic-webfont.svg#geosquadbold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'geosquad';
    src: url('/font/geosquad-regular-webfont.eot');
    src: url('/font/geosquad-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/geosquad-regular-webfont.woff2') format('woff2'),
         url('/font/geosquad-regular-webfont.woff') format('woff'),
         url('/font/geosquad-regular-webfont.ttf') format('truetype'),
         url('/font/geosquad-regular-webfont.svg#geosquadregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'geosquad';
    src: url('/font/geosquad-regular-italic-webfont.eot');
    src: url('/font/geosquad-regular-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/geosquad-regular-italic-webfont.woff2') format('woff2'),
         url('/font/geosquad-regular-italic-webfont.woff') format('woff'),
         url('/font/geosquad-regular-italic-webfont.ttf') format('truetype'),
         url('/font/geosquad-regular-italic-webfont.svg#geosquadregular_italic') format('svg');
    font-weight: normal;
    font-style: italic;
}
